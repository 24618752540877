<template>
  <Dialog
    header="Korfirmasi"
    v-model:visible="showDenyModal"
    :modal="true"
    position="center"
  >
    <div class="flex mb-4 flex-column">
      <div class="mb-2">Alasan</div>
      <Textarea v-model="reason" rows="5" cols="70" :disabled="loading" />
    </div>
    <template #footer>
      <button class="p-button p-component p-button-danger tw-px-10" disabled v-if="loading">
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px;" />
      </button>
      <Button label="Confirm" class="p-button-danger" @click="denySalon" v-else />
    </template>
  </Dialog>

  <Dialog
    header="Korfirmasi"
    v-model:visible="showApproveModal"
    :style="{width: '350px'}"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button label="Tidak" icon="pi pi-times" @click="showApproveModal = false" class="p-button-text"/>
      <button class="p-button p-component tw-px-10" disabled v-if="loading">
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px;" />
      </button>
      <Button label="Ya" icon="pi pi-check" @click="approveSalon" class="p-button" autofocus v-else/>
    </template>
  </Dialog>

  <router-link
    :to="{ name: 'salons' }"
    class="block mb-3 text-lg tw-font-semibold"
    v-if="!isAdminSalon()"
  >
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="mb-5 card">
    <div class="block mb-5 md:flex justify-content-between align-items-center">
      <h1 class="mb-2 tw-text-xl tw-font-bold sm:mb-0">Profil Salon</h1>
      <div>
        <Button
          icon="pi pi-pencil"
          class="py-2 p-button-warning"
          label="Edit Detail Salon"
          @click="
            router.push({
              name: isAdminSalon() ? 'edit-salon-admin' : 'edit-salon',
              params: isAdminSalon() ? null : {id: route.params.id}
            })
          "
        />
        <template v-if="salon.status === 0 && !isAdminSalon()">
          <Button
            class="py-2 mx-3 p-button-success sm:mb-0"
            :style="{ width: '100px' }"
            label="Setujui"
            @click="showApproveModal = true"
          />
          <Button
            class="py-2 p-button-danger"
            :style="{ width: '100px' }"
            label="Tolak"
            @click="denySalon"
          />
        </template>
      </div>
    </div>

    <div class="grid mb-5">
      <div class="col-12 md:col-4">
        <h3 class="tw-text-lg tw-font-semibold">Banner Utama</h3>
        <FormKit
          v-model="data"
          :type="uploadImage"
          name="image"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          :src="
            salon.image ? salon.image : require('@/assets/images/dummy.jpg')
          "
          :upload="updateServiceImage"
        />
      </div>

      <div class="col-0 md:col-1"></div>
      <div class="col-12 md:col-7">
        <h5 class="tw-text-lg tw-font-semibold">Detail</h5>
        <hr class="mt-2 mb-4" />
        <div
          class="grid mb-2"
          v-for="column in columns"
          :key="`_${column.field}`"
        >
          <div class="col-6 md:col-4 opacity-60">
            {{ column.header }}
          </div>
          <div class="col-6 md:col-8 tw-font-medium">
            <div v-if="!column?.process">
              <span v-if="column.field === 'service_type'">
                {{ serviceTypes[salon[column.field]] }}
              </span>
              <span v-else>
                {{ salon[column.field] || "-" }}
              </span>
            </div>
            <div v-else>{{ column.process(salon[column.field]) }}</div>
          </div>
        </div>
        <div
          class="grid"
          v-if="isAdminPriti() && salon.status !== 2 && salon.status !== 0"
        >
          <div class="col-6 md:col-4 opacity-60">Active</div>
          <div class="col-6 md:col-8">
            <InputSwitch
              v-model="salon.isActive"
              @change="activeInactiveSalon(salon.id, salon.isActive)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="mb-3 tw-text-lg tw-font-semibold">Pin Lokasi Peta</h3>
      <GMapMap
        :center="center"
        :zoom="10"
        map-type-id="terrain"
        style="width: 100%; height: 500px"
      >
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :draggable="true"
          />
        </GMapCluster>
      </GMapMap>
    </div>
  </div>

  <BankAccountList :salon-name="salon.name" />
  <BannerList :salon-id="route.params.id || salonId" />
</template>

<script setup>
import { useSalonForm } from "@/composables/salon";
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import BankAccountList from "./bank-accounts/BankAccountList";
import { useAuthRole } from "@/composables/auth";
import { createInput } from "@formkit/vue";
import FormUploadImage from "@/components/form/FormUploadImage";
import { defaultFormikClass } from "@/constants/formik";
import BannerList from "./banners/BannerList";

const route = useRoute();
const router = useRouter();
const { salonId } = useAuthRole();
const { isAdminPriti, isAdminSalon } = useAuthRole();
const showDenyModal = ref(false);
const showApproveModal = ref(false);
const reason = ref("");
const {
  salon,
  markers,
  center,
  getDetailSalon,
  approve,
  deny,
  activeInactiveSalon,
  serviceTypes,
  loading
} = useSalonForm();

const data = ref(null);
const uploadImage = createInput(FormUploadImage, {
  props: ["src", "upload"],
});

const { uploadMainBanner } = useSalonForm();

const updateServiceImage = async (data) => {
  if (isAdminPriti()) {
    await uploadMainBanner(route.params.id, data);
  } else {
    await uploadMainBanner(salon.value.id , data);
  }
  
  await getDetailSalon(route.params.id || salonId.value);
};

const columns = [
  { field: "name", header: "Nama" },
  { field: "description", header: "Deskripsi" },
  { field: "status_name", header: "Status" },
  { field: "address", header: "Alamat" },
  { field: "provinces_name", header: "Provinsi" },
  { field: "cities_name", header: "Kota" },
  { field: "districts_name", header: "Kecamatan" },
  // {
  //   field: "coverage_area",
  //   header: "Coverage Area",
  //   process: (data) => `${data} Km`,
  // },
  { field: "service_type", header: "Jenis Layanan" },
];

const approveSalon = async () => {
  await approve(route.params.id);
  showApproveModal.value = false;
};

const denySalon = async () => {
  if (!showDenyModal.value) {
    showDenyModal.value = true;
    reason.value = "";
    return;
  }

  if (reason.value === "") reason.value = " ";
  
  var resp = await deny(route.params.id || salonId.value, reason.value);
  showDenyModal.value = false;
  reason.value = "";

  if (resp.status === 200) {
    salon.value.status = 2;
  }
};

onBeforeMount(async () => {
  await getDetailSalon(route.params.id || salonId.value);
});
</script>

<style scoped></style>
